@import "@/index.scss";

.userspace-layout {
    margin: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    --nav-height: 70px;
    .userspace-nav {
        width: 100%;
        height: var(--nav-height);
        position: fixed;
        z-index: 10;
        top:0;
        left:0;
    }
    .userspace-content {
        width: 100%;
        height: 100%;
        padding: var(--nav-height)  var(--safe-padding) 0px var(--safe-padding);
        background: var(--bg-light);
        display: flex;
        gap:20px;
        @include media-breakpoint-down(lg){
            padding: var(--nav-height)  var(--safe-padding-small) 0px var(--safe-padding-small);
        }
        @include media-breakpoint-down(sm){
            flex-direction: column;
            height: fit-content;
            min-height: 100vh;
        }
        .userspace-side {
            height: 100%;
            width: 300px;
            @include media-breakpoint-down(lg){
                width: 200px;
            }
            @include media-breakpoint-down(sm){
                width: 100%;
            }
        }
        .userspace-body {
            flex:1;
            height: 100%;
            @include media-breakpoint-down(sm){
                width: 100%;
                padding-bottom: 20px;
            }
            .userspace-body-route-layout{
                margin: 20px auto auto auto;
                border-radius: 5px;
                background: var(--bg-white);
                width: 100%;
                height: calc( 100% - 40px);
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                box-shadow: 0px 0px 20px var(--bg-light-shade);
            }
        }
    }
}