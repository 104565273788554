@import "@/index.scss";

@mixin item() {
    width: 100%;
    height: 150px;
    border-radius: 5px;
    background: var(--bg-light);
    padding: 15px;
}

.campagne-details-layout-skeleton {
    max-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap:20px;
    padding: 20px;
    overflow: auto;
    .item-left{
        @include item();
        display: flex;
        flex-direction: column;
        gap:5px;
        .title{
            height: 1.1rem;
            width: 100%;
        }
        .date {
            height: 0.8rem;
            width: 100%;
        }
        .description {
            width: 100%;
            height: 3rem;
        }

    }
    .item-right{
        @include item();
        display: flex;
        align-items: center;
        justify-content: space-around;
        @include media-breakpoint-down(sm) {
            height:fit-content;
            flex-direction:column;
            align-items: center;
            gap: 20px
        }
        .chart-container{
            height: 100%;
            aspect-ratio: 1;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            //gap: 10px;
            @include media-breakpoint-down(sm){
                height: 200px;
                box-shadow: 0px 0px 50px var(--bg-light), 0px 0px 10px var(--bg-light);
                padding: 5px;
            }
            .chart-layout{
                flex: 1;
                width: 100%;
                // aspect-ratio: 1;
                // border: 5px solid var(--text-dark);
                // border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                .circ{
                    width: 80px;
                    height: 80px;
                }
            }
            .small {
                height: 0.75rem;
                width: 100%;
                @include media-breakpoint-down(sm){
                    height: 0.85rem;
                }
            }
        }
    }
    .table-container {
        @include item();
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
        .row-skeleton{
            height: 30px;
            width: 100%;
        }
    }
}