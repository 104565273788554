.campagne-item {

    border: 1px solid var(--bg-white);
    border-radius: 2px;
    width: 100%;
    padding: 10px;
    gap: 5px;
    display: flex;
    flex-direction: column;
    transition: all ease-in-out;
    position:relative;
    &.active {
        border: 1px solid var(--bg-light);
        background: var(--bg-light);
        .campagne-title{
            color: var(--text-secondary);
        }
        .campagne-type{
            img{
                filter: var(--img-filter-inactive-dark);
            }
        }
        &::after{
            content: '';
            position: absolute;
            right: 0px;
            top:0px;
            width: 5px;
            height: 100%;
            background: var(--bg-secondary);

        }
    }
    &:hover {
        cursor: pointer;
        border: 1px solid var(--bg-light);
        background: #fcfcfc;
    }
    .campagne-title {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.95rem;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--text-dark);
    }
    .campagne-type {
        display: flex;
        height: 20px;
        align-items: center;
        gap: 10px;
        img{
            height: 80%;
            width: auto;
            filter: var(--img-filter-inactive-light);
            &.active-sms {
                filter: var(--img-filter-sms);
                opacity: 1;
            }
            &.active-mail {
                filter: var(--img-filter-mail);
                opacity: 1;
            }
            &.active-whatsapp {
                filter: var(--img-filter-whatsapp);
                opacity: 1;
            }
        }
    }
    .campagne-description {
        max-height: 60px;
        font-size: 0.7rem;
        color: var(--text-medium);
        overflow: auto;
        text-overflow: clip;
    }
}