@import "@/index.scss";

.side-container {
  margin: 20px auto auto auto;
  border-radius: 5px;
  background: var(--bg-white);
  padding: 20px 10px 10px 10px;
  width: 100%;
  height: calc( 100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0px 0px 20px var(--bg-light-shade);
  gap: 10px;

  @include media-breakpoint-down(sm){
    height: fit-content;
  }
  .side-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--bg-light-shade);

    .sidebar-nav{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 100%;

      .sidebar{
        display: flex;
        align-items: center;
        gap: 10px;
        background: transparent;
        border: 1px solid var(--bg-secondary);
        color: var(--bg-secondary);
        box-shadow: 0px 3px 5px rgba($color: #000000, $alpha: 0.2);
        height: 45px;
        font-size: 0.94rem;
        text-transform: uppercase;
        padding: 0px 10px;
        border-radius: 5px;
        text-decoration: none;

        .icon{
          height: 25px;
          width: auto;
        }

        &.btn-add{
          flex: 1;
        }

        &.active{
          background-color: var(--bg-secondary);
          color: #ffffff;
        }

      }
    }
    
    .side-search,.side-filter {
      height: 40px;
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 10px;
      border-radius: 5px;
      border: 1px solid  var(--bg-light);
      padding: 0px 15px 0px 5px;
      .search-icon {
        height:40%;
        width: auto;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
      }
      input {
        height: 85%;
        border-radius: 5px;
        background: var(--bg-white);
        width: 100%;
        font-size: medium;
        padding-left: 30px;
        border: none;
        outline: none;
        font-size: 1rem;
      }
      select {
        height: 85%;
        width: 100%;
        border-radius: 5px;
        background: var(--bg-white);
        font-size: medium;
        padding-left: 30px;
        border: none;
        outline: none;
        font-size: 1rem;
      }
    }
  }
  small {
    color: var(--text-dark);
  }
  .content-body {
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;

    @include media-breakpoint-down(sm){
      max-height: 300px;
    }

  }
}
