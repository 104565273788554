@import "@/index.scss";


.dashboard-container {
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;
  position: relative;

  .title {
    width: 100%;
    display: flex;
    align-items: center;
    span {
      font-size: 1.2rem;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  
  .stat-container{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    grid-auto-flow: row dense;
    justify-content: center;
    justify-self: center;
    gap: 30px;

    .stat-item{
      border: 1px solid #e4e4e4;
      border-bottom: 7px solid;
      border-radius: 7px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
      padding: 30px 20px;
      box-shadow: 0px 3px 5px rgba($color: #000000, $alpha: 0.1);

      .stat-header{
        padding: 8px 10px;
        background-color: rgba($color: #000000, $alpha: 0.08);
        color: #000000;
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 3px;
        font-weight: 600;

        .stat-icon{
          font-size: 1.5rem;
        }
      }

      .stat-body{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .value{
          font-size: 2.5rem;
          font-weight: bold;
        }
      }

      .stat-footer{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        button{
          height: 38px;
          min-width: 150px;
          border: 1px solid #e4e4e4;
          border-radius: 22.5px;
          font-size: 0.92rem;
          font-weight: 600;
          text-transform: uppercase;
          color: var(--text-success);
          background-color: transparent;
        }
      }

      &.color-red{
        border-bottom-color: var(--text-danger);
        .stat-header{
          background-color: rgba($color: #D32F2F, $alpha: 0.08);
          color: #D32F2F;
        }
      }
      &.color-orange{
        border-bottom-color: var(--text-secondary);
        .stat-header{
          background-color: rgba($color: #F37327, $alpha: 0.08);
          color: #F37327;
        }
      }
      &.color-blue{
        border-bottom-color: var(--text-primary);
        .stat-header{
          background-color: rgba($color: #2997DB, $alpha: 0.08);
          color: #2997DB;
        }
      }
      &.color-purple{
        border-bottom-color: #8A93FF;
        .stat-header{
          background-color: rgba($color: #8A93FF, $alpha: 0.08);
          color: #8A93FF;
        }
      }
    }
  }

  .pie-chart-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    min-height: fit-content;
    padding-top: 20px;

    .chart-item{
      width: calc((100% / 2 ) - 15px);
      min-height: 480px;
      border: 1px solid #e4e4e4;
      border-radius: 7px;
      box-shadow: 0px 3px 15px rgba($color: #000000, $alpha: 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
    }

  }
}
