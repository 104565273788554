@import "@/index.scss";

.error-boundary-container{
    font-family: 'Open sans', sans-serif !important;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: var(--bg-primary);
    //background: #ffcb3b;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    @include media-breakpoint-down(sm){
        padding: 20px;
    }
    .middle-card{
        width: 100%;
        height: 100%;
        background: var(--bg-white);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        gap: 10px;
        align-items: center;
        justify-content: center;
        position: relative;
        .oops{
            display: flex;
            align-items: center;
            gap: 10px;
            span{
                font-size: 8rem;
                font-weight: bold;
                color: var(--text-dark);
                @include media-breakpoint-down(sm){
                    font-size: 5rem;
                }
            }
            img{
                height: 10rem;
                width: auto;
                @include media-breakpoint-down(sm){
                    height: 5rem;
                }
            }
        }
        p{
            text-align: center;
            font-weight: normal;
            font-size: 1.2rem;
            width: 100%;
            small{
                color: var(--text-dark-tint);
            }
        }
        .option{
            margin-top: 50px;
            display: flex;
            align-items: center;
            gap: 100px;
            @include media-breakpoint-down(sm){
                flex-direction: column;
                gap: 20px;
                button{
                    width: 100%;
                    padding: 10px;
                }
            }
        }
    }

}