@import "@/index.scss";


@mixin button-filter(){
    background: var(--bg-white);
    box-shadow: 0px 0px 10px var(--bg-light-shade);
    padding: 5px 10px;
    color: var(--text-dark);
    @include media-breakpoint-down(sm){
        width: 100%;
        padding: 10px;
    }
    &:hover{
        background: var(--bg-secondary-light);
    }
}

.table-toolbar-container{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    background: var(--bg-light);
    border-bottom: 1px solid var(--bg-light-shade);
    border-radius: 2px 2px 0px 0px;
    padding: 10px;
    @include media-breakpoint-down(sm){
        flex-direction: column;
    }
    .toolbar-filter{
        @include button-filter();
    }
    .toolbar-column{
        @include button-filter();
    }
    .toolbar-export{
        @include button-filter();
    }
}

.table-viewer-layout{
    width: 100%;
    height: fit-content;
    .MuiDataGrid-columnHeadersInner {
        .MuiDataGrid-columnHeaderTitle{
            font-weight: bold;
        }
    }
    .MuiDataGrid-cellContent{
        font-size: 0.75rem;
    }
}