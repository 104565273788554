@import "@/index.scss";

.loader-container{
    position: absolute;
    top: 0;
    left:0;
    height: 0%;
    width: 0%;
    background: var(--bg-white);
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    transition: all ease-in-out 300ms;
    &.active {
        display: flex;
        height: 100%;
        width: 100%;
        background: rgba(0,0,0, 0.4);
        // border-radius: 20px;
        z-index: 1;
    }
    span{
        font-size: 1.2rem;
        color: var(--text-white);
        @include media-breakpoint-down(sm){
            font-size: 0.9rem;
            font-weight: bold;
        }
    }
}