@import "@/index.scss";

.loading-screen{
    height: 100vh;
    width: 100%;
    background: var(--bg-white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    span{
        font-size: 1.2rem;
        color: var(--text-primary);
        @include media-breakpoint-down(sm){
            font-size: 0.9rem;
            font-weight: bold;
        }
    }
    img{
        width: 10%;
        height: auto;
        @include media-breakpoint-down(sm){
            width: 20%;
        }
    }
}