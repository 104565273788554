@import "@/index.scss";

@mixin item() {
    width: 100%;
    height: 150px;
    border-radius: 5px;
    box-shadow: 0px 0px 50px var(--bg-light), 0px 0px 10px var(--bg-light);
    padding: 15px;
}

.user-details-layout {
    max-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap:20px;
    padding: 20px;
    overflow: auto;
    .title-part{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap:5px;
        .title{
            font-size: 1.1rem;
            font-weight: bold;
            color: var(--text-dark);
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: uppercase;
        }
        .add-btn{
            min-width: 220px;
            height: 46px;
            display: flex;
            align-items: center;
            line-height: normal;
        }
        .date {
            font-size: 0.8rem;
            color: var(--text-medium);
            width: 100%;
            text-align: left;
        }
        .description {
            width: 100%;
            flex:1;
            font-size: 0.75rem;
            color: var(--text-dark-tint);
            overflow: auto;
        }

    }
    .item-right{
        @include item();
        display: flex;
        align-items: center;
        justify-content: space-around;
        @include media-breakpoint-down(sm) {
            height:fit-content;
            flex-direction:column;
            align-items: center;
            gap: 20px
        }
        .chart-container{
            height: 100%;
            aspect-ratio: 1;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            //gap: 10px;
            @include media-breakpoint-down(sm){
                height: 200px;
                box-shadow: 0px 0px 50px var(--bg-light), 0px 0px 10px var(--bg-light);
                padding: 5px;
            }
            img{
                height: 1.2rem;
                width: auto;
                filter: var(--img-filter-inactive-dark);
                @include media-breakpoint-down(sm){
                    height: 2rem;
                }
                &.sms{
                    filter: var(--img-filter-sms);
                    & + .chart-layout {
                        & + small{
                            b{
                                color: var(--text-secondary);
                            }
                        }
                    }
                }
                &.mail{
                    filter: var(--img-filter-mail);
                    & + .chart-layout {
                        & + small{
                            b{
                                color: var(--text-primary);
                            }
                        }
                    }
                }
                &.whatsapp{
                    filter: var(--img-filter-whatsapp);
                    & + .chart-layout {
                        & + small{
                            b{
                                color: var(--text-green-whatsapp);
                            }
                        }
                    }
                }
            }
            .chart-layout{
                flex: 1;
                width: 100%;
                // aspect-ratio: 1;
                // border: 5px solid var(--text-dark);
                // border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            small {
                font-size: 0.75rem;
                color: var(--text-medium);
                text-align: center;
                margin-top: auto;
                line-height: 90%;
                @include media-breakpoint-down(sm){
                    font-size: 0.85rem;
                }
            }
        }
    }
    .table-container {
        @include item();
        min-height: 300px;
        height: fit-content;
        padding: 10px;

        .btns-actions{
            display: flex;
            align-items: center;
            gap: 10px;

            button{
                border: none;
                height: 30px;
                width: 30px;
                font-size: 1.2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 2px;
                cursor: pointer;

                &.btn-edited{
                    color: var(--text-white);
                    background-color: rgba($color: #5cb85C, $alpha: 0.90);
                    &:hover{
                        background-color: rgba($color: #5cb85C, $alpha: 0.50);
                        color: var(--text-dark);
                    }
                }
                &.btn-locked{
                    color: var(--text-dark);
                    background-color: rgba($color: #F3732A, $alpha: 0.70);
                    &:hover{
                        background-color: rgba($color: #F3732A, $alpha: 0.30);
                    }
                }
                &.btn-deleted{
                    color: var(--text-white);
                    background-color: rgba($color: #D32F2F, $alpha: 0.90);
                    &:hover{
                        background-color: rgba($color: #D32F2F, $alpha: 0.50);
                    }
                }
            }
        }
    }
}