@import "@/index.scss";

.nav-container {
  margin: 0;
  padding: 0 var(--safe-padding);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-secondary);
  background: var(--bg-white);
  box-shadow: 0px 0px 20px var(--bg-light-shade);
  @include media-breakpoint-down(lg){
    padding: 0 var(--safe-padding-small);
  }
  .nav-part-left {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nav-logo {
      height: 100%;
      width: fit-content;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-bottom: 5px solid var(--bg-secondary);
      img {
        height: 50%;
        width: auto;
      }
    }
    .nav-option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
      width: 200px;
      .option {
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          margin: 0 7px;
          height: 22px;
        }
        span {
          font-size: 1rem;
        }
      }
    }
  }
  .nav-part-right {
    display: flex;
    align-items: center;
    gap: 50px;

    .profil{
      border: none;
      background-color: transparent;
      box-shadow: none;
      display: flex;
      align-items: center;
      gap: 10px;

      .photo-id{
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        // border: 1px solid #eeeeee;
        border-radius: 50%;

        img{
          width: 40px;
        }
      }

      .names{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0px;

        @include media-breakpoint-down(lg){
          display: none;
        }

        span{
          color: var(--text-dark);
          font-weight: 600;
          font-size: 1rem;
          // text-transform: capitalize;
        }
        small{
          color: var(--text-medium);
        }
      }

      .icon-chevron{
        color: var(--text-dark);
        font-size: 1.2rem;
        border: 1px solid var(--text-dark);
      }
    }

    .navbar-menu-container {
      width: fit-content;
      height: 46px;
      min-width: unset ;
      padding: 0;
      background-color: var(--bg-white);
      border: 1px solid var(--bg-secondary);
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: var(--text-dark);


      .menu-item{
        height: 100%;
        border-radius: none;
        display: flex;
        gap: 10px;
        color: var(--text-dark);
        background-color: transparent;
        box-shadow: none;

        .navbar-icon{
          font-size: 1.6rem;

          @include media-breakpoint-down(sm){
            font-size: 1rem;
          }

        }

        &.active{
          background-color: var(--bg-secondary);
          color: var(--text-white);
          font-weight: 600;
        }

        @include media-breakpoint-down(lg){
          span {
            display: none;
          }
        }
      }

      @include media-breakpoint-down(lg){
       margin: 10px;
      }

    }
  }
}
