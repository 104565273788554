
.campagne-item-skeleton{
    background: var(--bg-light);
    border-radius: 2px;
    width: 100%;
    padding: 10px;
    gap: 5px;
    display: flex;
    flex-direction: column;
    transition: all ease-in-out;
    position:relative;
    &:hover {
        cursor: pointer;
        border: 1px solid var(--bg-light);
        background: #fcfcfc;
    }
    .campagne-title {
        width: 100%;
        height: 1rem;
    }
    .campagne-type {
        display: flex;
        height: 20px;
        align-items: center;
        gap: 10px;
        .img{
            height: 20px;
            width: 20px;
        }
    }
    .campagne-description {
        width: 100%;
        height: 3rem;
    }
}